import React, {useEffect, useState} from 'react';
import LazyLoad from 'react-lazyload';
import './App.css';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Logo from "./Logo"
import {Alert, Button, Card, Link, Typography} from "@mui/material";

import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/lazy";
import "swiper/css/pagination";

import {FreeMode, Lazy, Navigation, Pagination} from "swiper";

import Youtube from "./Youtube";

function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

function App() {

    const [productsList, setProductsList] = useState([]);
    const [addedProducts, setAddedProducts] = useState([]);

    const mainProductId = parseInt(getCookie('product_id'))
    const phone = decodeURIComponent(getCookie('phone'))
    const orderId = decodeURIComponent(getCookie('order_id'))
    const client = decodeURIComponent(getCookie('client')).replaceAll('+',' ')

    //Для тестов
    // const mainProductId = 3272
    // const client = 'Тест не трогать'
    // const phone = '380951234567'
    // const orderId = '54655445454545'

    const backurl = "http://" + window.location.hostname
    const editphone = '+' + phone.replaceAll('+', ' ').replaceAll('-', ' ')

    const getProductsList = async (url) => {
        let result;

        await fetch(url)
            .then(response => response.json())
            .then(response => result = {status: 'success', response})
            .catch(error => result = {status: 'error', error})
        return result;
    }

    const getProducts = async () => {
        let arr = [];
        let request = await getProductsList(`https://api.v2.yellowshop.in.ua/upsells/product/${mainProductId}`);

        if (request.status === "success") {
            for (const product of request.response.data) {
                arr.push(product);
            }
        }
        setProductsList(arr);
    }

    useEffect(() => {
        getProducts()
    }, []);

    const getSwiper = (index, slides) => {
        return (
            <Card raised sx={{position: 'relative', borderRadius: '0px', backgroundColor: '#313642', mt:'-5px'}}>
                <Swiper
                    style={{
                        "--swiper-navigation-color": "#b4b4b4",
                        "--swiper-pagination-color": "#313642"
                    }}
                    lazy={true}
                    pagination={true}
                    loop={true}
                    spaceBetween={0}
                    navigation={true}
                    modules={[Pagination, FreeMode, Navigation, Lazy]}
                    className="mySwiper2"
                >
                    <div className="ribbon"><span>Знижка</span></div>
                    {slides}
                </Swiper>
            </Card>
        )
    }

    const addToOrder = async (e, productId, price) => {
        e.preventDefault();
        let result = ''

        setAddedProducts(oldArray => [...oldArray, productId])

        await fetch(`https://form-ok.yellowshop.in.ua/success.php?product_id=${productId}&price=${price}&bayer_name=${client} доп ${orderId}-${Date.now()}&phone=${phone}&comment=` + 'Доп до замовлення ' + orderId)
            .then(response => response.json())
            .then(response => result = {status: 'success', response})
            .catch(error => result = {status: 'error', error})
    }

    const listItems = productsList.map((value, index) => {
        const productName = value.name
        const arrayImages = value.images
        const arrayTheses = value.theses
        const productId = value.productId
        const price = value.price
        const prevPrice = Math.trunc(price * (Math.random() + 1))
        const isActive = value.enabled

        if (!isActive) return

        if (productId === mainProductId) return

        const slides = arrayImages.map((img, index) => {
            return (
                <SwiperSlide key={index}>
                    <img data-src={`https://multimedia.yellowshop.in.ua/images/${img.uuid}`} className="swiper-lazy" alt={''}/>
                </SwiperSlide>)
        });

        const theses = arrayTheses.map((value, index) => {
            return (
                <Typography key={index} sx={{
                    mr: 1,
                    ml: 2,
                    display: "inline",
                    color: '#ffffff',
                    fontFamily: 'Bebas Neue Bold',
                    fontWeight: 700,
                    padding: 1
                }} variant="h6">{value}</Typography>
            )
        })

        let splitedProductName = productName.split(" ");
        let firstWord = splitedProductName[0];
        let secondWords = splitedProductName.slice(1, 3).join(" ");
        let otherWords = splitedProductName.slice(3).join(" ");
        let videoId = value.videoId;
        let description = value.description;

        let buttonText = addedProducts.includes(productId) ? '  Додано  ' : 'Додати до замовлення'

        return (
            <Grid item key={value.name} sx={{
                minWidth: 350,
                maxWidth: 350
            }}>
                <LazyLoad height={1000} offset={1000}>
                    <Paper elevation={3}
                           sx={{
                               height: 'auto',
                               width: '100%',
                               background: '#313642',
                               borderBottom: '1px solid #484b54'
                           }}
                    >
                        <Box display="flex"
                             justifyContent="center"
                             alignItems="center"
                             sx={{padding: 1, textAlign: 'center',flexDirection:"column"}}>
                            <div>
                                <Typography sx={{
                                    display: "inline",
                                    color: 'white',
                                    fontFamily: 'Bebas Neue Bold',
                                    fontWeight: 700,
                                    textTransform: 'uppercase',
                                    marginRight: 1
                                }} variant="h4">{firstWord}</Typography>

                                <Typography sx={{
                                    display: "inline",
                                    color: '#ff4853',
                                    fontFamily: 'Bebas Neue Bold',
                                    fontWeight: 700,
                                    textTransform: 'uppercase',
                                    maxWidth: '180px'
                                }} variant="h4">{secondWords}</Typography>
                            </div>
                            <Typography sx={{
                                display: "inline",
                                color: '#ff4853',
                                fontFamily: 'Bebas Neue Bold',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                            }} variant="h4">{otherWords}</Typography>
                        </Box>

                        {getSwiper(index, slides)}

                        {videoId !== '' &&
                            <div>
                                <Box display="flex"
                                     justifyContent="center"
                                     alignItems="center"
                                     sx={{padding: 2}}>
                                    <Typography sx={{
                                        display: "inline",
                                        color: 'white',
                                        fontFamily: 'Bebas Neue Bold',
                                        fontWeight: 700,
                                        textTransform: 'uppercase',
                                        marginRight: 1
                                    }} variant="h4">Вiдео</Typography>

                                    <Typography sx={{
                                        display: "inline",
                                        color: '#00e391',
                                        fontFamily: 'Bebas Neue Bold',
                                        fontWeight: 700,
                                        textTransform: 'uppercase',
                                    }} variant="h4">Огляд</Typography>
                                </Box>

                                <Box display="flex"
                                     justifyContent="center"
                                     alignItems="center"
                                >
                                    <Youtube width="300px" height="300px" videoId={videoId}/>
                                </Box>
                            </div>
                        }

                        <Box display="flex"
                             justifyContent="center"
                             alignItems="center"
                             sx={{mt: 2}}>
                            <Typography sx={{
                                textAlign:"center",
                                color: 'white',
                                fontFamily: 'Bebas Neue Bold',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                textDecoration: "line-through",
                                backgroundColor: '#242628',
                                width: '100%',
                                transition: 'all 0.2s',
                                boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 1)',
                                padding: '0.2em 0.9em 0.2em'
                            }} variant="h4">{prevPrice} грн</Typography>
                            <Typography sx={{
                                textAlign:"center",
                                display: "inline",
                                width: '100%',
                                color: 'white',
                                fontFamily: 'Bebas Neue Bold',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                background: 'linear-gradient(to bottom, #ff4853 0%,#d3232e 100%)',
                                transition: 'all 0.2s',
                                boxShadow: '0px 3px 3px 0px rgba(167, 0, 0, 1)',
                                padding: '0.2em 0.9em 0.2em'
                            }} variant="h4">{price} грн</Typography>
                        </Box>

                        <Typography
                            sx={{
                                color: 'white',
                                fontFamily: 'Bebas Neue Bold',
                                padding: 2,
                                fontWeight: 700,
                                fontSize: "1.25em",
                            }}
                        >{description}</Typography>

                        <Box display="flex"
                             flexDirection="column"
                             justifyContent="center"
                             alignItems="left"
                             sx={{padding: 0.5, backgroundColor: "#272a34"}}
                        >
                            {theses}
                        </Box>

                        <Box display="flex"
                             justifyContent="center"
                             alignItems="center"
                             sx={{padding: 2}}>
                            <Typography sx={{
                                display: "inline",
                                color: 'white',
                                fontFamily: 'Bebas Neue Bold',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                marginRight: 1
                            }} variant="h4">Замовляйте</Typography>

                            <Typography sx={{
                                display: "inline",
                                color: '#00e391',
                                fontFamily: 'Bebas Neue Bold',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                            }} variant="h4">Зараз</Typography>
                        </Box>

                        <Box display="flex"
                             justifyContent="center"
                             alignItems="center"
                             sx={{padding: 2, backgroundColor: "#272a34"}}>

                            <Button
                                sx={{
                                    background: 'linear-gradient(to bottom, rgba(251,245,3,1) 0%,rgba(251,213,3,1) 100%)',
                                    color: '#222222',
                                    fontFamily: 'Bebas Neue Bold',
                                    fontWeight: 900,
                                    transition: 'all 0.2s',
                                    boxShadow: '0 4px 0 0 #c6a800',
                                    fontSize: '30px',
                                    textShadow: '0 1px 0 rgb(255 255 255 / 60%)',
                                    padding: "20px 10px",
                                    minWidth: 280
                                }}
                                disabled={addedProducts.includes(productId)}
                                size={'large'}
                                variant="contained"
                                onClick={(event) => addToOrder(event, productId, price)}
                            > {buttonText}</Button>
                        </Box>
                    </Paper>
                </LazyLoad>
            </Grid>
        )
    })

    useEffect(() => {
    }, [productsList]);

    return (
        <Box sx={{
            width: '100%',
            mt: 2
        }}>
            <div className="list">
                <Grid container
                      rowSpacing={3}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                >
                    <Grid sx={{
                        minWidth: 350,
                        maxWidth: 350,
                        mt: 1
                    }}>
                        <Alert severity="success">
                            <Typography sx={{
                                fontWeight: 500,
                                color: 'black'
                            }} variant="h6">Дякуємо за замовлення!
                            </Typography>

                            <Box display="flex"
                                 flexDirection="row"
                                 justifyContent="left"
                                 alignItems="left"
                                 sx={{mt: 1}}
                            >
                                <Typography sx={{
                                    fontWeight: 500,
                                    mr: 1,
                                    color: 'black'
                                }} variant="h7">ПІБ:
                                </Typography>

                                <Typography sx={{
                                    fontWeight: 500,
                                    color: 'black'
                                }} variant="h7"> {client}
                                </Typography>
                            </Box>

                            <Box display="flex"
                                 flexDirection="row"
                                 justifyContent="left"
                                 alignItems="left"
                                 sx={{mt: 0, mb: 1}}
                            >
                                <Typography sx={{
                                    fontWeight: 500,
                                    mr: 1,
                                    color: 'black'
                                }} variant="h7">Номер:
                                </Typography>

                                <Typography sx={{
                                    fontWeight: 500,
                                    color: 'black'
                                }} variant="h7"> {editphone}
                                </Typography>
                            </Box>

                            <Box display="flex"
                                 flexDirection="column"
                                 justifyContent="left"
                                 alignItems="left"
                                 sx={{mt: 0, mb: 1}}
                            >
                                <Typography sx={{
                                    fontWeight: 500,
                                    mt: 1,
                                    color: 'black'
                                }} variant="h7">Якщо помилилися ви можете
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                    }} variant="h7">
                                    <Link color={'blue'} href={backurl} underline="none">
                                        залишити заявку ще раз
                                    </Link>.
                                </Typography>
                            </Box>

                            <Box display="flex"
                                 flexDirection="row"
                                 justifyContent="left"
                                 alignItems="left"
                                 sx={{mt: 2}}
                            >
                                <Typography sx={{
                                    fontWeight: 500,
                                    color: 'black'
                                }} variant="h7">Графік роботи:
                                </Typography>

                                <Typography sx={{
                                    fontWeight: 500,
                                    ml: 1,
                                    color: 'black'
                                }} variant="h7">ПН-НД 9:00-20:00
                                </Typography>
                            </Box>
                        </Alert>
                    </Grid>
                    {listItems}
                </Grid>
            </div>
        </Box>
    );
}

export default App;
