import React, {useEffect, useState} from 'react'
import YouTubeLogo from "./assets/img/youtube-default.webp"

const Youtube = (props) => {
    const { videoId, imgSize } = props
    const [showVideo, setShowVideo] = useState(false)
    const [isValid, setValid] = useState(false);

    const validVideoId = (id) => {
        let img = new Image();
        img.src = "http://img.youtube.com/vi/" + id + "/mqdefault.jpg";
        img.onload = function () {
            setValid(checkThumbnail(this.width));
        }
    }

    const checkThumbnail = (width) => {
        return width !== 120;
    }

    useEffect(() => {
        validVideoId(videoId )
    }, [videoId,validVideoId]);

    return (
        <React.Fragment>
            {showVideo ? (
                <iframe
                    allow="autoplay"
                    width={'100%'}
                    height={'300px'}
                    src={`https://www.youtube.com/embed/${videoId}`}
                    style={{border:0}}
                    allowFullScreen
                ></iframe>
            ) : (
                <div
                    style={{
                        backgroundImage: `url(${
                            !isValid
                                ? YouTubeLogo
                                : `https://img.youtube.com/vi/${videoId}/${imgSize || 'mqdefault'}.jpg`
                        })`,
                        width: '100%',
                        height: '200px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: !isValid ? '#fff' : '#000',
                        cursor: 'pointer',
                    }}
                    onClick={() => setShowVideo(true)}
                >
                    <svg style={{
                        height: '20%',
                        width: '20%',
                    }}
                         version="1.1"
                         viewBox="0 0 68 48">
                        <path d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fillOpacity="0.8"></path>
                        <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                    </svg>
                </div>
            )}
        </React.Fragment>
    )
}
export default Youtube